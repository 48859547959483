<template>
  <div id="home">
    <v-container id="goals" tag="section" style="max-width: 1168px">
      <h1 class="pt-5">{{ $t('activities.near-title') }}</h1>
      <v-row>
        <v-col v-for="(feature, i) in features2" :key="i" class="d-flex" cols="12" sm="6" md="4">
          <v-card outlined>
            <v-img :src="feature.src" height="325px" contain />
            <v-card-title
              class="align-start"
              style="min-height: 40px"
              v-text="getTranslation(feature.title)"
            />
            <v-card-text class="pb-5 pt-3" v-text="getTranslation(feature.text)" />
          </v-card>
        </v-col>
      </v-row>

      <h1 class="pt-5">{{ $t('activities.walking-distance-title') }}</h1>
      <v-row>
        <v-col v-for="(feature, i) in walking" :key="i" class="d-flex" cols="12" sm="6" md="4">
          <v-card outlined>
            <v-img :src="feature.src" height="325px" />
            <v-card-title
              class="align-start"
              style="min-height: 40px"
              v-text="getTranslation(feature.title)"
            />
            <v-card-text class="pb-5 pt-3" v-text="getTranslation(feature.text)" />
          </v-card>
        </v-col>
      </v-row>

      <h1 class="pt-5">{{ $t('activities.close-by-title') }}</h1>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" class="d-flex" cols="12" sm="6" md="4">
          <v-card outlined>
            <v-img :src="feature.src" max-height="350px" />
            <v-card-title
              class="align-start"
              style="min-height: 40px"
              v-text="getTranslation(feature.title)"
            />
            <v-card-subtitle v-text="feature.subtitle + getTranslation('activities.with-car')" />
            <v-card-text class="pb-5 pt-3" v-text="getTranslation(feature.text)" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Aktiviter',
    meta: [{ name: 'description', content: 'Saker att göra i närheten av Majostugan' }],
  },

  methods: {
    getTranslation(text) {
      return this.$i18n.t(text);
    },
  },

  data: () => ({
    features: [
      {
        title: 'activities.golf-title',
        subtitle: '3',
        text: 'activities.golf',
        src: '/static/golf.webp',
        to: 'https://www.funasfjallengolf.se',
      },
      {
        title: 'Funäsdalsberget',
        subtitle: '6',
        text: 'activities.funas',
        src: '/static/activities-hero.webp',
      },
      {
        title: 'activities.village-title',
        subtitle: '8',
        text: 'activities.village',
        src: '/static/ica.webp',
      },
      {
        title: 'Bruksvallarna',
        subtitle: '10',
        text: 'activities.bruksvallarna',
        src: '/static/bruksvallarna.webp',
      },
      {
        title: 'Ramundberget',
        subtitle: '15',
        text: 'activities.ramund',
        src: '/static/ramundberget.webp',
      },
      {
        title: 'Tänndalen',
        subtitle: '20',
        text: 'activities.tanndalen',
        src: '/static/tanndalen.webp',
      },
    ],
    features2: [
      {
        title: 'activities.ski-title',
        text: 'activities.ski',
        src: '/static/skidspar2.webp',
      },
      {
        title: 'activities.mtb-title',
        text: 'activities.mtb',
        src: '/static/cykelled.webp',
      },
      {
        title: 'activities.snow-mobile-title',
        text: 'activities.snow-mobile',
        src: '/static/skoter.webp',
      },
    ],
    walking: [
      {
        title: 'activities.fishing-title',
        text: 'activities.fishing',
        src: '/static/fiske.webp',
      },
      {
        title: 'activities.swimming-title',
        text: 'activities.swimming',
        src: '/static/ljusnan.webp',
      },
    ],
  }),
};
</script>

<style></style>
